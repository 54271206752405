import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { appApiResources, ReservationPromocodeQueryString } from 'src/app/app.constants';
import { ReservationPayload } from 'src/app/pages/reservation/reservation.model';
import { CommonService } from '../common.service';
import { HttpInputData, HttpWrapperService } from '../core/http/http-wrapper.service';
import { AppStateService } from '../init/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class OpenReservationService {
  outletID: string;
  appData: any;


  constructor(
    private httpWrapper: HttpWrapperService,
    private commonService: CommonService,
    public appState: AppStateService,
  ) {

  }

  verifyMobile(type,value) {
    const options = new HttpInputData();
    let httpParams = new HttpParams();
    // httpParams = httpParams.set('mobile', value);
    if(type =='mobile') {
      httpParams = httpParams.set('mobile', value);
    } else {
      httpParams = httpParams.set('email', value);
    }

   

    options.params = httpParams
    return this.httpWrapper.get(appApiResources.openNewReservation, options).map((res) => {
      if (res) {

        return res;
      }
    }).toPromise()
  }

  verifyMobileAndEmailMake(value,typeSend) {
    console.log("value", value)
    console.log("typeSend", typeSend)
    const options = new HttpInputData();
    let httpParams = new HttpParams();
   
    if(typeSend =='mobile') {
      httpParams = httpParams.set('mobile', value);
    } else {
      httpParams = httpParams.set('email', value);
    }

    options.params = httpParams
    return this.httpWrapper.get(appApiResources.openNewReservation, options).map((res) => {
      if (res) {

        return res;
      }
    }).toPromise()
  }


  getOutletList(employeeID: string) {
    const options = new HttpInputData();
    let httpParams = new HttpParams();
    httpParams = httpParams.set('employeesfid', employeeID);
    options.params = httpParams;
    return this.httpWrapper.get(appApiResources.outletList, options).map(
      (res) => {
        if (res) {
          return res
        }
      }
    ).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      })
  }

  applyPromocode(queryString: ReservationPromocodeQueryString) {
    const options = new HttpInputData();
    let httpParams = new HttpParams();
    Object.keys(queryString).forEach((key) => {
      httpParams = httpParams.set(key, queryString[key])
    });
    options.params = httpParams;
    return this.httpWrapper.get(appApiResources.promocodeForReservation, options).map((res) => {
      if (res) {
        return res;
      }
    }).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      })
  }

  getDetailsOfOutletAndBenefit(id: string, type: "Outlet" | "Certificate" | "Card" | "Promocode") {

    console.log("id=>>", id)
    console.log("type=>>", type)
    // let options = new HttpInputData();
    // let httpParams = new HttpParams();
    let options = new HttpInputData();
    let httpParams = new HttpParams();
    httpParams = httpParams.set("uniqueIdentifier", id);
    httpParams = httpParams.set("offerType", type);
    options.params = httpParams;

    return this.httpWrapper.get(appApiResources.detailApi, options).map((res) => {
      if (res) {
        return res
      }
    }).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      });
  }

  doReservation(payload: ReservationPayload, queryString: { outletID: string, membershipNumber?: string }, identifier?) {
    console.log("queryString---", queryString)
    const options = new HttpInputData();
    let url
    if(identifier == "existingApi"){
       url = appApiResources.createReservation;
    }
    else {
       url = appApiResources.openTableReservation;
    }
   

    console.log("querystring", queryString, "payload",payload, "newUrl", url )
    if (queryString.outletID) {
      url = url + `?outletID=${queryString.outletID}`
    }

    if (queryString.membershipNumber) {
      url = url + `&membershipNumber=${queryString.membershipNumber}`
    }
    

    return this.httpWrapper.post(url, payload, options).map((res) => {
      if (res) {
        return res;
      }
    }).toPromise()
      .catch((error) => {
        this.commonService.errorHandler(error);
      });
  }


  walkinApi(data,identify) {

    var sendUrl
    if (identify == 'oldApi') {
      sendUrl = appApiResources.reservationwalkinUrl
    } else {
      sendUrl = `openTableReservation?outletID=${data.outletsfid}&type=open-walk-in`
    }
    let options = new HttpInputData();
    return this.httpWrapper.post(sendUrl, data, options)
      .pipe(map((response: any) => {
        return response;
      })
        , catchError((error: Response) => this.commonService.errorHandler(error))
      )
  }

  verifyMobileAndEmail(callMethod:string,typeCheck) {
    const options = new HttpInputData();
    let httpParams = new HttpParams();
   
    if(typeCheck =='mobile') {
      httpParams = httpParams.set('mobile', callMethod);
    } else {
      httpParams = httpParams.set('email', callMethod);
    }
   
    options.params = httpParams
    return this.httpWrapper.get(appApiResources.openNewReservation, options).map((res) => {
      if (res) {

        return res;
      }
    }).toPromise()
    // .catch((error) => {
    //   if(error.error.errorCode =='TLC-973') {

    //     return
    //   } else {
    //     this.commonService.errorHandler(error);

    //   }
    // }
    // )
  }
}
