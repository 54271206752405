// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

// The list of file replacements can be found in `angular.json`.

export const environment = {
  GOOGLE_API_KEY: 'AIzaSyDkELl13-zb7dskCM7l2UK0eJz5uLRzFWU',
  production: false,
  brand: 'mariott',
  //Start Chat section for Club marroit
  // liveAgentPod: 'd.la1-core1.sfdc-y37hzm.salesforceliveagent.com', // FOR PROD
  // orgId: '00D0o000001B5aH', // FOR PROD
  // deploymentId: '5722j000000CaS0', // FOR PROD  
  // buttonId: '5732j000000CaRH', // FOR PROD
  liveAgentPod: 'd.la2-c1cs-ukb.salesforceliveagent.com', // FOR UAT 
  orgId: '00D0T0000008kNm', // FOR UAT 
  deploymentId: '5720T0000008OIo', // FOR UAT 
  buttonId: '5730T0000008OK1',  // FOR UAT 
  //End Chat section for Club marroit

  CountryName: "India",
  CountryCode: "in",
  CountryDialCode: "91",
  brandName: "Club Marriott",
  languageArr: ['en', 'hi'], //Language array
  Content: "/content/tlc", //for ADDing AEM API Identifier request
  AppBrandName: 'AppBrandName.brand', // For Support Multi Languages like Language array
  taxPercentKey: 'GST',
  headers: {
    client_id: 'HDRDGHTYUIKIOKSAAASS',
    client_secret: 'HDRDGHTYUIKIOKSAAASS',
    appid: 'com.tlcgroup.cm_h',
    program_id: 'TLC_MAR_CLMA',
    subprogramid: 'clubMarriot',
    country: 'IN', // FIXME: should be part of geo coder
    nationality: 'Indian',
    language: 'en',
  },
  // baseUrl: 'https://hotelapp.tlcgroup-platform.com/api/', //Prod
  // baseUrl: 'https://hotelapp-dev-int.tlcgroup-test-platform.com/api/',//New SIT URL
  baseUrl: 'https://hotelapp-uat.tlcgroup-test-platform.com/api/', //New UAT URL

  appDBName: '__mydbmarriott',
  // aem_base_url: 'https://stage.clubmarriott.in/bin/tlc/identifiersExporter',
  // aem_url_exporter: 'https://stage.clubmarriott.in/bin/tlc/walletcardexporter',// UAT STAGE
  aem_base_url: "https://tlc-loyalty-program-uat.herokuapp.com/api/tlc/identifiersExporter",
  aem_url_exporter: "https://tlc-loyalty-program-uat.herokuapp.com/api/tlc/walletcardexporter",
  getIPinfo: "https://pro.ip-api.com/json/?key=O80jLviQeItbkWK",


  // // For Prod
  // aem_url_exporter: 'https://prod.clubmarriott.in/bin/tlc/walletcardexporter', // PROD 
  // aem_base_url: 'https://prod.clubmarriott.in/bin/tlc/identifiersExporter', //AEM Production URL
  //End Prod
  aem_sildes_url: '',

  // sso_url: ' https://sso-sit.tlcgroup-test-platform.com/saml/login'//SIT 
  sso_url: 'https://sso.tlcgroup-platform.com/saml/login', //Prod

};

